import axios from "axios";

const API_ENV = {
  production: "production",
  staging: "staging",
  oldProduction: "oldProduction",
};

export const apiEnvDict = [
  { title: "Staging", value: API_ENV.staging },
  { title: "Production", value: API_ENV.production },
  { title: "oldProduction", value: API_ENV.oldProduction },
];

export const getCurrentEnv = () => localStorage.getItem("env") || API_ENV.test;
export const setCurrentEnv = (env) => {
  localStorage.setItem("env", env);
  setTimeout(() => window.location.reload(), 300);
};

export const getApiBaseURL = () => {
  const env = getCurrentEnv();
  if (env === API_ENV.production) return "https://api.we-conect.com";
  if (env === API_ENV.oldProduction) return "https://api.hubs101.com";
  if (env === API_ENV.staging) return "https://api-staging.hubs101.com";
  return "";
};

export default axios.create({
  baseURL: `${getApiBaseURL()}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});
